import { useState } from "react";
import { FaStar } from "react-icons/fa";

export default function StartRating({ noOfStarts = 5 }) {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const handleClick = (getCurrentIndex) => {
    setRating(getCurrentIndex);
  };

  const handleMouseEnter = (getCurrentIndex) => {
    setHover(getCurrentIndex);
  };

  const handleMouseLeave = () => {
    setHover(rating);
  };

  return (
    <div className="flex items-center space-x-2">
      {[...Array(noOfStarts)].map((_, index) => {
        index += 1;

        return (
          <FaStar
            key={index}
            className={`transition-colors duration-200 ease-in-out ${
              index <= (hover || rating)
                ? "text-yellow-500 hover:text-yellow-400"
                : "text-gray-400 hover:text-yellow-500"
            } cursor-pointer`}
            onClick={() => handleClick(index)}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
            size={40}
          />
        );
      })}
    </div>
  );
}
