import React, { useEffect, useRef, useState } from "react";
import Image from "../../assets/img/Image.jpg";

import useAOS from "../../assets/Util/aos";
import AboutMe from "./aboutme.modal";

const About = () => {
  useAOS("fadeOut", { duration: 2500, offset: 100 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 0);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div id="about" className="bg-slate-600 p-8" data-aos="fade-out">
      <div className="container mx-auto bg-gray-300 shadow-lg rounded-lg p-8 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* bg-gradient-to-r from-blue-800 via-blue-200 to-blue-500 */}
        {/* Left Column */}
        <div className="col-span-1 md:border-r md:pr-8">
          <div className="flex flex-col items-center">
            <img
              src={Image}
              alt="Profile"
              className="w-32 h-32 rounded-full mb-4"
            />
            <h2 className="text-xl font-bold">Iskren Nikolaev Iliev</h2>
          </div>
          <div className="mt-6">
            <p>
              <strong>Birth date:</strong> 22.07.2001
            </p>
            <p>
              <strong>Gender:</strong> Male
            </p>
            <p>
              <strong>Citizenship:</strong> Bulgaria
            </p>
            <p>
              <strong>I live in:</strong> Sofia/Bulgaria
            </p>
          </div>
          <div className="mt-6">
            <h3 className="font-semibold text-lg mb-2">Contacts</h3>
            <ul className="list-none">
              <li>Email: iskren201@gmail.com</li>
              <li>Phone: +359 882424811</li>
              <li>
                Address: Sofia, 1324 g.k. Lyulin 7, str. "Major Nikola Ivanov"
              </li>
              <li>
                GitHub:{" "}
                <a
                  href="https://github.com/Iskren201"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  https://github.com/Iskren201
                </a>
              </li>
              <li>
                LinkedIn:{" "}
                <a
                  href="https://www.linkedin.com/in/iskren-iliev-31a809257/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  https://www.linkedin.com/in/iskren-iliev-31a809257/
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-6">
            <h3 className="font-semibold text-lg mb-2">Skills</h3>
            <ul className="list-disc pl-5 space-y-1 text-gray-700">
              <li>AngularJS</li>
              <li>ReactJS</li>
              <li>NodeJS</li>
              <li>NestJS</li>
              <li>TypeORM</li>
              <li>PostgreSQL</li>
              <li>PgAdmin</li>
              <li>Postman</li>
              <li>GitHub</li>
            </ul>
          </div>
        </div>

        {/* Right Column */}
        <div className="col-span-2">
          <h2 className="text-2xl font-bold mb-4">Education</h2>
          <div className="mb-4">
            <h3 className="text-xl font-semibold">
              Bachelor, Information Technologies, New Bulgarian University
            </h3>
            <p>Sofia</p>
            <p>
              <em>October 2021 - Present</em>
            </p>
            <p>
              Description/Main Subjects: Programming, Discrete math, Web
              programming with HTML and JavaScript, Operating Systems, Data
              structures
            </p>
          </div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold">
              Secondary school, 79 Indira Gandhi Secondary School
            </h3>
            <p>Sofia</p>
            <p>
              <em>September 2008 - May 2020</em>
            </p>
            <p>
              Description/Main Subjects: Mathematics, Information technologies,
              Informatics
            </p>
          </div>

          <h2 className="text-2xl font-bold mb-4">Languages</h2>
          <div className="mb-6">
            <p>
              <strong>Native language:</strong> Bulgarian
            </p>
            <p>
              <strong>English:</strong> Comprehension (Intermediate), Speaking
              (Intermediate), Writing (Intermediate)
            </p>
          </div>

          <h2 className="text-2xl font-bold mb-4">Work Experience</h2>

          <div className="mb-6">
            <h3 className="text-xl font-semibold">
              Full Stack Developer, Hermes
            </h3>
            <p className="text-sm text-gray-600">
              February 2024 - Business sector: Information technologies
            </p>
            <p>
              As a Full Stack Developer at Hermes, I support and develop the
              company's platforms for buying and selling parts, ensuring high
              stability and functionality. My responsibilities include
              implementing new features and developing modules tailored to
              business needs, optimizing user experience, and fixing bugs. I
              also adapt the platform to meet dynamic business requirements by
              creating innovative solutions. <br />
              <strong>Technologies used:</strong> TypeScript, Angular, NestJS,
              TypeORM, PgAdmin
            </p>
          </div>

          <div className="mb-6">
            {/* <p>
              I also have one year of non-professional experience with
              JavaScript, HTML, CSS, ReactJS, Tailwind CSS, GitHub, Linux, and
              Postman. I am a self-taught front-end developer, having taken
              courses at SoftUni and the university, while developing
              independent projects. I have worked on several small projects,
              utilizing the technologies I have mastered to deliver functional,
              responsive web solutions.
            </p> */}
          </div>

          <h2 className="text-2xl font-bold mb-4">Courses and Certificates</h2>
          <div className="mb-6">
            <ul className="list-none">
              <li>
                HTML & CSS, Software University -{" "}
                <a
                  href="https://softuni.bg/Certificates/Details/162901/d39a2a66"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Certificate
                </a>
              </li>
              <li>
                Programming Basics with JavaScript -{" "}
                <a
                  href="https://softuni.bg/certificates/details/182722/f12fe3a6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Certificate
                </a>
              </li>
              <li>Programming Fundamentals with JS - September 2023</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button onClick={openModal} className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg">
          About Me
        </button>

        <AboutMe ref={modalRef} isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default About;
