import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AOS_OPTIONS = {
  fadeIn: {
    duration: 1500,
    easing: "ease-in",
    once: true,
    offset: 100,
  },
  fadeOut: {
    duration: 2000,
    easing: "ease-out",
    once: false,
    offset: 50,
  },
  fadeUp: {
    duration: 2000,
    easing: "ease-in-out",
    once: true,
    offset: 100,
  },
  FlipLeft: {
    duration: 2500,
    easing: "flip-left",
    once: true,
    offset: 100,
  },
};

const useAOS = (type, customOptions = {}) => {
  useEffect(() => {
    const options = AOS_OPTIONS[type] || {};

    AOS.init({
      ...options,
      ...customOptions,
    });

    return () => AOS.refresh();
  }, [type, customOptions]);
};

export default useAOS;
