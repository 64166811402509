import React, { forwardRef, useEffect } from "react";
import {
  FaReact,
  FaNodeJs,
  FaAngular,
  FaGit,
  FaDatabase,
} from "react-icons/fa";
import {
  SiTypescript,
  SiExpress,
  SiPostgresql,
  SiNestjs,
  SiTypeorm,
  SiJira,
  SiBitbucket,
} from "react-icons/si";

const techStack = [
  { name: "React.js", icon: <FaReact className="text-blue-600" /> },
  { name: "TypeScript", icon: <SiTypescript className="text-blue-600" /> },
  { name: "Node.js", icon: <FaNodeJs className="text-green-600" /> },
  { name: "Express.js", icon: <SiExpress className="text-gray-600" /> },
  { name: "PostgreSQL", icon: <SiPostgresql className="text-purple-600" /> },
  { name: "NestJS", icon: <SiNestjs className="text-red-600" /> },
  { name: "TypeORM", icon: <SiTypeorm className="text-blue-600" /> },
  { name: "Angular", icon: <FaAngular className="text-red-600" /> },
  { name: "GitHub", icon: <FaGit className="text-gray-600" /> },
  { name: "Jira", icon: <SiJira className="text-blue-600" /> },
  { name: "Bitbucket", icon: <SiBitbucket className="text-blue-600" /> },
];

const AboutMe = forwardRef(({ isOpen, onClose }, ref) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const handleOverlayClick = (event) => {
    if (event.target === ref.current) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      ref={ref}
      className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-lg p-8 w-full max-w-lg shadow-xl transform transition-all duration-300 scale-100">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 transition-colors duration-200"
          onClick={onClose}
        >
          ✖
        </button>

        <h2 className="text-4xl font-bold mb-4 text-center text-gray-800">
          About Me
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          I have been programming since the beginning of university. During this
          time, I gained knowledge, skills, and experience in teamwork by
          participating in projects with 3+ members. I have a strong interest in
          web development and am eager to grow in this field.
        </p>

        <h3 className="text-3xl font-semibold mb-4 text-gray-800">
          Tech Stack
        </h3>
        <ul className="flex flex-wrap gap-4 mb-6">
          {techStack.map((tech, index) => (
            <li
              key={index}
              className="flex items-center text-gray-700 transition-transform transform hover:scale-105"
            >
              <span className="mr-2 text-2xl">{tech.icon}</span>
              {tech.name}
            </li>
          ))}
        </ul>

        <div className="mt-6">
          <button
            onClick={onClose}
            className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-500 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
});

export default AboutMe;
