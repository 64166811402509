import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { MenuList } from "./menu-list";

export const MenuItem = ({ item }) => {
  const [displayChildren, setDisplayChildren] = useState({});

  const handleToggleChildren = (getCurrentLabel) => {
    setDisplayChildren({
      ...displayChildren,
      [getCurrentLabel]: !displayChildren[getCurrentLabel],
    });
  };

  return (
    <li className="py-2 px-4">
      <div className="flex items-center justify-between cursor-pointer hover:bg-gray-200 rounded-md transition-colors">
        <p className="text-lg font-semibold text-gray-800">{item.label}</p>
        {item.children && item.children.length > 0 && (
          <span onClick={() => handleToggleChildren(item.label)}>
            {displayChildren[item.label] ? (
              <FaMinus color="#333" size={20} />
            ) : (
              <FaPlus color="#333" size={20} />
            )}
          </span>
        )}
      </div>
      {item.children &&
        item.children.length > 0 &&
        displayChildren[item.label] && <MenuList list={item.children} />}
    </li>
  );
};
