export const User = ({ user }) => {
  const {
    avatar_url,
    followers,
    following,
    public_repos,
    name,
    login,
    created_at,
  } = user;

  const createDate = new Date(created_at);

  return (
    <div className="user bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-6">
      <div className="flex flex-col items-center mb-6">
        <img
          src={avatar_url}
          alt="Avatar"
          className="w-20 h-20 rounded-full border-4 border-blue-500"
        />
        <div className="mt-4 text-center">
          <a
            href={`https://github.com/${login}`}
            className="text-xl font-semibold text-blue-600 hover:text-blue-800"
          >
            {name || login}
          </a>
          <p className="text-gray-500 text-sm mt-2">
            User joined on{" "}
            {`${createDate.getDate()} ${createDate.toLocaleString("en-us", {
              month: "short",
            })} ${createDate.getFullYear()}`}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
        <div className="stat-item">
          <p className="text-gray-600 font-medium">Public Repos</p>
          <p className="text-lg font-semibold text-gray-800">{public_repos}</p>
        </div>
        <div className="stat-item">
          <p className="text-gray-600 font-medium">Followers</p>
          <p className="text-lg font-semibold text-gray-800">{followers}</p>
        </div>
        <div className="stat-item">
          <p className="text-gray-600 font-medium">Following</p>
          <p className="text-lg font-semibold text-gray-800">{following}</p>
        </div>
      </div>
    </div>
  );
};
