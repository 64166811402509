import { useState } from "react";
import data from "./data";

export default function Accordion() {
  const [selected, setSelected] = useState(null);
  const [enableMultiSelection, setEnableMultiSelection] = useState(false);
  const [multiple, setMultiple] = useState([]);

  function handleSingleSelection(getCurrentId) {
    setSelected(getCurrentId === selected ? null : getCurrentId);
  }

  function handleMultiSelection(getCurrentId) {
    let cpyMultiple = [...multiple];
    const findIndexOfCurrentId = cpyMultiple.indexOf(getCurrentId);
    if (findIndexOfCurrentId === -1) cpyMultiple.push(getCurrentId);
    else cpyMultiple.splice(findIndexOfCurrentId, 1);

    setMultiple(cpyMultiple);
  }

  return (
    <div className="wrapper max-w-3xl mx-auto py-10">
      <button
        onClick={() => setEnableMultiSelection(!enableMultiSelection)}
        className="mb-6 bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
      >
        {enableMultiSelection
          ? "Disable Multi Selection"
          : "Enable Multi Selection"}
      </button>

      <div className="accordion space-y-4">
        {data && data.length > 0 ? (
          data.map((dataItem) => (
            <div
              key={dataItem.id}
              className="item border border-gray-300 text-black rounded-lg shadow-md overflow-hidden"
            >
              <div
                onClick={
                  enableMultiSelection
                    ? () => handleMultiSelection(dataItem.id)
                    : () => handleSingleSelection(dataItem.id)
                }
                className="title bg-gray-200 px-4 py-3 cursor-pointer flex justify-between items-center hover:bg-gray-300 transition duration-200"
              >
                <h3 className="text-lg font-semibold">{dataItem.question}</h3>
                <span className="text-xl font-bold">
                  {enableMultiSelection
                    ? multiple.indexOf(dataItem.id) !== -1
                      ? "-"
                      : "+"
                    : selected === dataItem.id
                    ? "-"
                    : "+"}
                </span>
              </div>
              {enableMultiSelection
                ? multiple.indexOf(dataItem.id) !== -1 && (
                    <div className="content bg-white p-4 text-gray-700 border-t">
                      {dataItem.answer}
                    </div>
                  )
                : selected === dataItem.id && (
                    <div className="content bg-white p-4 text-gray-700 border-t">
                      {dataItem.answer}
                    </div>
                  )}
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">No data found!</div>
        )}
      </div>
    </div>
  );
}
