import React, { useState } from "react";
import emailjs from "emailjs-com";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        "service_91r8f6i",
        "template_ww40o1s",
        e.target,
        "0UaqWXcidwDYPFfIx"
      )
      .then(
        () => {
          Toastify({
            text: "Message sent successfully!",
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#4caf50", // Green for success
          }).showToast();
        },
        () => {
          Toastify({
            text: "Failed to send the message.",
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#f44336", // Red for error
          }).showToast();
        }
      )
      .finally(() => {
        setIsSending(false);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      });
  };

  return (
    <div id="contact" className="bg-slate-600 p-8 sm:p-12 lg:p-16">
      <h2 className="text-4xl font-extrabold mb-8 text-center text-white">
        Contact Me
      </h2>
      <div className="max-w-3xl mx-auto bg-slate-700 p-8 rounded-lg shadow-2xl">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-gray-300 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-3 border border-gray-200 rounded-lg bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                placeholder="Your Name"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-300 mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-3 border border-gray-200 rounded-lg bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                placeholder="Your Email"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-semibold text-gray-300 mb-2"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="6"
              className="w-full px-4 py-3 border border-gray-200 rounded-lg bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
              placeholder="Your Message"
            />
          </div>
          <button
            type="submit"
            disabled={isSending}
            className={`w-full py-3 px-4 rounded-lg shadow-md transition duration-300 ${
              isSending ? "bg-gray-600" : "bg-green-800 hover:bg-green-700"
            } text-white focus:outline-none focus:ring-2 focus:ring-indigo-500`}
          >
            {isSending ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </div>
  );
};
