import React, { useEffect } from "react";
import projectsData from "./projectsData";
import { ScrollIndicator } from "./scroll-indicator/scroll-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ParticlesComponent from "../utils/particle";

export const Project = () => {
  useEffect(() => {
    toast.warning(
      "This project is a work in progress! I'm currently focusing on improving the styling and adding more projects."
    );
  }, []);

  //   TODO: projectData to fix Title and content and to fix the style all project!

  return (
    //! this is the old code the original
    // <div className="bg-gray-500 py-10 min-h-screen">
    //   <ScrollIndicator />
    //   <div className="container mx-auto px-4">
    //     <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-800">
    //       My Projects
    //     </h1>

    //     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
    //       {projectsData.map((project) => (
    //         <div
    //           key={project.id}
    //           className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300"
    //         >
    //           {project.component}
    //         </div>
    //       ))}
    //     </div>
    //   </div>

    //   <ToastContainer />
    // </div>

    <div className="bg-gray-700 py-10 min-h-screen">
      <ParticlesComponent />
      <ScrollIndicator />
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-800">
          My Projects
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {projectsData.map((project) => (
            <div
              key={project.id}
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300 transform hover:scale-105 mx-4"
            >
              <h3 className="text-xl font-semibold flex justify-center text-gray-900 mb-4">
                {project.title}
              </h3>
              <p className="text-gray-600 mb-4">{project.description}</p>
              {project.component}
            </div>
          ))}
        </div>
      </div>

      {/* Toast Container to render toast notifications */}
      <ToastContainer />
    </div>
  );
};
