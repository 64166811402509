import { useState } from "react";
import { Modal } from "./modal";

export const ModalTest = () => {
  const [showModalPopup, setShowModalPopup] = useState(false);

  const handleToggleModalPopup = () => {
    setShowModalPopup(!showModalPopup);
  };

  const onClose = () => {
    setShowModalPopup(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <button
        onClick={handleToggleModalPopup}
        className="px-6 py-3 bg-blue-500 text-black font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
      >
        Open Modal
      </button>
      {showModalPopup && (
        <Modal
          onClose={onClose}
          header="Modal Header"
          body={<div>Customized body content goes here!</div>}
          footer={
            <button
              onClick={onClose}
              className="px-6 py-2 bg-gray-500 text-white rounded-md"
            >
              Close
            </button>
          }
        />
      )}
    </div>
  );
};
