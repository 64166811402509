import { Tabs } from "./tabs";

export const TabTest = () => {
  const tabs = [
    {
      label: "Tab 1",
      content: <div>This is Content for Tab 1</div>,
    },
    {
      label: "Tab 2",
      content: <div>This is Content for Tab 2</div>,
    },
    {
      label: "Tab 3",
      content: <div>This is Content for Tab 3</div>,
    },
  ];

  const handleChange = (currentTabIndex) => {
    console.log("Current Tab Index:", currentTabIndex);
  };

  return <Tabs tabsContent={tabs} onChange={handleChange} />;
};
