import { useEffect, useState } from "react";
import { User } from "./user";

export const GithubProfileFinder = () => {
  const [userName, setUserName] = useState("iskren201");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSubmit = () => {
    fetchGithubUserData();
  };

  const fetchGithubUserData = async () => {
    setLoading(true);
    const res = await fetch(`https://api.github.com/users/${userName}`);
    const data = await res.json();

    if (data) {
      setUserData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGithubUserData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <h1 className="text-2xl text-gray-700">Loading Data, Please Wait...</h1>
      </div>
    );
  }

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      {/* Search Input */}
      <div className="flex justify-center items-center space-x-4 mb-6">
        <input
          type="text"
          name="search-by-username"
          placeholder="Search Github Username ..."
          value={userName}
          onChange={(event) => setUserName(event.target.value)}
          className="px-4 py-2 w-64 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
        />
        <button
          onClick={handleSubmit}
          className="px-6 py-2 bg-blue-500 text-black rounded-md hover:bg-blue-600 transition duration-300"
        >
          Search
        </button>
      </div>

      {/* Display User Data */}
      {userData !== null ? <User user={userData} /> : null}
    </div>
  );
};
