import React from "react";
import Typewriter from "typewriter-effect";
import Image from "../../assets/img/BgImage.png";

import { FaGithub, FaLinkedin } from "react-icons/fa";

export const Main = () => {
  return (
    <section
      id="home"
      className="flex flex-col items-center justify-center h-screen bg-slate-600 text-white text-center px-6 py-12"
    >
      {/* <ParticlesComponent /> */}

      <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 tracking-wide shadow-md">
        Hi, I'm Iskren Iliev
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-6">
        <Typewriter
          options={{
            strings: [
              "Front-End Developer",
              "Creative Coder",
              "Full Stack experience",
              "Problem Solver",
            ],
            autoStart: true,
            loop: true,
            cursor: "|",
            delay: 75,
          }}
        />
      </h2>
      <p className="text-lg md:text-xl lg:text-2xl max-w-2xl mx-auto mb-8 leading-relaxed">
        I specialize in building modern, responsive websites and web
        applications using React, JavaScript, and other cutting-edge
        technologies.
      </p>

      {/* Image Section */}
      <div className="w-full max-w-md mx-auto mb-6 border-b-8 border-t-8 border-cyan-600">
        <img
          src={Image}
          alt="Full Stack Developer"
          className="w-full h-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="flex space-x-4 mt-6">
        <a
          href="https://github.com/Iskren201"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:text-black transition duration-300"
        >
          <FaGithub size={40} />
        </a>
        <a
          href="https://www.linkedin.com/in/iskren-iliev-31a809257/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:bg-blue-200 hover:text-blue-500 transition duration-300"
        >
          <FaLinkedin size={40} />
        </a>
      </div>

      <a
        href="#contact"
        className="mt-6 px-6 py-3 bg-green-800 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-green-900 transition duration-300 ease-in-out"
      >
        Get in Touch
      </a>
    </section>
  );
};
