export const Modal = ({ id, header, body, footer, onClose }) => {
  return (
    <div
      id={id || "Modal"}
      className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50"
    >
      <div className="modal-content bg-white rounded-lg shadow-lg w-full max-w-lg mx-4 p-6">
        <div className="header flex justify-between items-center border-b-2 pb-4 mb-4">
          <h2 className="text-xl font-semibold">{header || "Modal Header"}</h2>
          <span
            onClick={onClose}
            className="cursor-pointer text-2xl text-gray-700 hover:text-red-600"
          >
            &times;
          </span>
        </div>
        <div className="body mb-4 text-gray-700">
          {body ? body : <p>This is the default modal body content.</p>}
        </div>
        <div className="footer flex justify-end">
          {footer ? (
            footer
          ) : (
            <button
              onClick={onClose}
              className="px-6 py-2 bg-gray-400 text-black rounded-md"
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
