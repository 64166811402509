import { useEffect, useState } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

export const ImageSlider = ({ url, limit = 5, page = 1 }) => {
  const [images, setImages] = useState([]); // Store images as an array
  const [currentSlide, setCurrentSlide] = useState(0);
  const [errorMsh, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchImages = async (getUrl) => {
    try {
      setLoading(true);
      const response = await fetch(`${getUrl}?page=${page}&limit=${limit}`);
      const data = await response.json();

      if (Array.isArray(data)) {
        // Ensure data is an array
        setImages(data);
        setErrorMsg(null);
      } else {
        setErrorMsg("Unexpected data format."); // Handle unexpected format
      }
      setLoading(false); // Loading finished
    } catch (e) {
      setErrorMsg(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url) {
      fetchImages(url);
    }
  }, [url, page, limit]);

  if (loading) {
    return (
      <div className="text-center text-xl text-gray-700">
        Loading data! Please Wait
      </div>
    );
  }

  if (errorMsh) {
    return (
      <div className="text-center text-xl text-red-500">Error! {errorMsh}</div>
    );
  }

  return (
    <div className="relative max-w-full mx-auto overflow-hidden">
      <BsArrowLeftCircleFill
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-4xl text-gray-800 hover:text-gray-600 cursor-pointer z-10 transition duration-300"
        onClick={() =>
          setCurrentSlide((prev) => (prev > 0 ? prev - 1 : images.length - 1))
        }
      />

      {images.length ? (
        <img
          key={images[currentSlide].id}
          alt={images[currentSlide].download_url}
          src={images[currentSlide].download_url}
          className="current-image w-full h-auto rounded-lg shadow-lg object-cover transition-transform duration-500 ease-in-out"
        />
      ) : null}

      <BsArrowRightCircleFill
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-4xl text-gray-800 hover:text-gray-600 cursor-pointer z-10 transition duration-300"
        onClick={() =>
          setCurrentSlide((prev) => (prev < images.length - 1 ? prev + 1 : 0))
        }
      />

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.length
          ? images.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${
                  currentSlide === index ? "bg-yellow-500" : "bg-gray-300"
                } transition-colors duration-300`}
                onClick={() => setCurrentSlide(index)}
              />
            ))
          : null}
      </div>
    </div>
  );
};
