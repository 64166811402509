const data = [
    {
      id: "1",
      question: "What are accordion components?",
      answer:
        "Accordion components are user interface elements used to organize content into expandable sections, allowing users to toggle between showing and hiding sections of related content.",
    },
    {
      id: "2",
      question: "What are the uses for accordion components?",
      answer:
        "Accordion components are commonly employed in various contexts, including FAQs, dashboards, and sections where you want to conserve space while organizing content efficiently.",
    },
    {
      id: "3",
      question: "What is an accordion as a musical instrument?",
      answer:
        "The accordion is a musical instrument with a keyboard and bellows, played by expanding and compressing the bellows while pressing keys or buttons to produce sound.",
    },
    {
      id: "4",
      question: "How does an accordion component improve UI/UX?",
      answer:
        "An accordion component improves UI/UX by reducing clutter on the screen, allowing users to focus on specific sections of content without being overwhelmed by too much information at once.",
    },
    {
      id: "5",
      question: "What is the difference between a toggle and an accordion?",
      answer:
        "While both toggles and accordions allow content to expand and collapse, accordions are typically used in a grouped format where only one section is open at a time, whereas toggles are standalone elements.",
    },
    //   {
    //     id: "6",
    //     question: "Can accordion components be nested?",
    //     answer:
    //       "Yes, accordion components can be nested within each other. This allows for even more complex and hierarchical content organization in user interfaces.",
    //   },
    //   {
    //     id: "7",
    //     question:
    //       "What are the benefits of using accordion components in mobile design?",
    //     answer:
    //       "Accordion components are especially beneficial in mobile design as they help save screen space, improve navigation, and allow users to access only the information they need without scrolling through long pages.",
    //   },
    //   {
    //     id: "8",
    //     question: "How is accessibility handled in accordion components?",
    //     answer:
    //       "Accessibility in accordion components can be improved by using semantic HTML elements like `<button>` and `<section>` with proper ARIA roles, attributes, and keyboard navigation support for users relying on assistive technologies.",
    //   },
    //   {
    //     id: "9",
    //     question: "How can you customize the style of accordion components?",
    //     answer:
    //       "Accordion components can be customized using CSS or styling libraries, allowing you to change colors, sizes, animations, and even the appearance of the toggling icons to fit your application's design.",
    //   },
    //   {
    //     id: "10",
    //     question:
    //       "What are the best practices for implementing accordion components?",
    //     answer:
    //       "Best practices include keeping content concise, ensuring only one section is open at a time for focus, optimizing for mobile, and ensuring accessibility for all users through proper HTML and ARIA roles.",
    //   },
  ];
  
  export default data;
  