import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll"; // ScrollLink for internal links
import { Link as RouterLink, useLocation } from "react-router-dom"; // RouterLink for navigation
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialNavigation = [
  { name: "Home", href: "home" },
  { name: "About", href: "about" },
  { name: "Experience", href: "experience" },
  { name: "Contact", href: "contact" },
  { name: "Project", href: "/project" },
];

const Navbar = () => {
  const [active, setActive] = useState("home");
  const location = useLocation();

  const handleNavClick = (href) => {
    setActive(href);
  };

  return (
    <Disclosure
      as="nav"
      className="bg-gradient-to-r from-gray-800 via-slate-600 to-gray-800 shadow-md sticky top-0 z-50"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              {/* Mobile menu button */}
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-300 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white transition duration-200 ease-in-out">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {/* Logo and large screen navigation */}
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0">
                  {location.pathname === "/project" ? (
                    <RouterLink
                      to="/"
                      className="text-white font-bold text-2xl cursor-pointer"
                    >
                      Iskren Iliev
                    </RouterLink>
                  ) : (
                    <ScrollLink
                      to="home"
                      smooth={true}
                      duration={500}
                      className="text-white font-bold text-2xl cursor-pointer"
                    >
                      Iskren Iliev
                    </ScrollLink>
                  )}
                </div>
                {/* Desktop Menu */}
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {initialNavigation.map((item) =>
                      item.name === "Project" ? (
                        <RouterLink
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            active === item.href
                              ? "text-white bg-gray-800"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium cursor-pointer transition duration-200 ease-in-out"
                          )}
                          onClick={() => handleNavClick(item.href)}
                        >
                          {item.name}
                        </RouterLink>
                      ) : location.pathname === "/project" ? (
                        <RouterLink
                          key={item.name}
                          to="/"
                          className={classNames(
                            active === item.href
                              ? "text-white bg-gray-800"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium cursor-pointer transition duration-200 ease-in-out"
                          )}
                          onClick={() => handleNavClick(item.href)}
                        >
                          {item.name}
                        </RouterLink>
                      ) : (
                        <ScrollLink
                          key={item.name}
                          to={item.href}
                          smooth={true}
                          duration={500}
                          onClick={() => handleNavClick(item.href)}
                          className={classNames(
                            active === item.href
                              ? "text-white bg-gray-800"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium cursor-pointer transition duration-200 ease-in-out"
                          )}
                        >
                          {item.name}
                        </ScrollLink>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {initialNavigation.map((item) =>
                item.name === "Project" ? (
                  <RouterLink
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      active === item.href
                        ? "text-white bg-gray-800"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    onClick={() => handleNavClick(item.href)}
                  >
                    {item.name}
                  </RouterLink>
                ) : location.pathname === "/project" ? (
                  <RouterLink
                    key={item.name}
                    to="/"
                    className={classNames(
                      active === item.href
                        ? "text-white bg-gray-800"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    onClick={() => handleNavClick(item.href)}
                  >
                    {item.name}
                  </RouterLink>
                ) : (
                  <ScrollLink
                    key={item.name}
                    to={item.href}
                    smooth={true}
                    duration={500}
                    onClick={() => handleNavClick(item.href)}
                    className={classNames(
                      active === item.href
                        ? "text-white bg-gray-800"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                  >
                    {item.name}
                  </ScrollLink>
                )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
