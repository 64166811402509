import { useEffect, useState } from "react";
import { Card } from "./card"; // Assuming Card is another component for displaying search results

export const SearchAutocomplete = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [filterUser, setFilterUsers] = useState([]);

  const fetchListOfUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://dummyjson.com/users`);

      const data = await response.json();

      if (data && data.users && data.users.length) {
        setUsers(data.users.map((userItem) => userItem.firstName));
        setLoading(false);
        setError(null);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListOfUsers();
  }, []);

  const handleChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchParam(query);
    if (query.length > 1) {
      const filterData =
        users && users.length
          ? users.filter((item) => item.toLowerCase().indexOf(query) > -1)
          : [];

      setFilterUsers(filterData);
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="relative w-80 max-w-full h-48 p-4 bg-white rounded-lg shadow-lg">
        <input
          value={searchParam}
          type="text"
          name="search-user"
          placeholder="Search user here..."
          onChange={handleChange}
          className="w-full h-12 p-3 pl-10 pr-4 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {showDropDown && filterUser.length > 0 && (
          <div className="absolute left-0 right-0 mt-2 max-h-32 overflow-auto bg-white border border-gray-300 rounded-lg shadow-lg z-10">
            <Card data={filterUser} />
          </div>
        )}

        {loading && (
          <div className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10 text-center py-2 text-gray-500">
            Loading...
          </div>
        )}
        {error && (
          <div className="absolute left-0 right-0 mt-2 bg-white border border-red-500 rounded-lg shadow-lg z-10 text-center py-2 text-red-500">
            Error fetching users
          </div>
        )}
      </div>
      <p>Try to type 'ma', 'em'.</p>
    </div>
  );
};
