import { Routes, Route } from "react-router-dom"; 
import About from "./components/about/about";
import { Contact } from "./components/contact/contact";
import Experience from "./components/experience/experience";
import { Main } from "./components/main/main";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import { Project } from "./project";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <section id="home">
              <Main />
            </section>
            <section id="about">
              <About />
            </section>
            <section id="experience">
              <Experience />
            </section>
            <section id="contact">
              <Contact />
            </section>
            <Footer />
          </>
        } />
        <Route path="/project" element={<Project />} />
      </Routes>
    </div>
  );
}

export default App;
