import Accordion from "./Accordion";
import { ModalTest } from "./custom-modal/modal-test";
import { TabTest } from "./custom-tabs/tabs-test";
import { GithubProfileFinder } from "./github-profile-finder";
import { ImageSlider } from "./image-slider";
import { LightDarkMode } from "./light-dark-mode";
import { LoadMoreData } from "./load-more-data";
import { QRCodeGenerator } from "./qr-code-generator";
import RandomColor from "./random-color";
import { SearchAutocomplete } from "./search-autocomplete";
import StartRating from "./start-rating";
import { TicTacToe } from "./tic-tac-game";
import { TreeView } from "./tree-view";
import Menu from "./tree-view/data";

// TODO: Fix Title and content

const projectsData = [
  {
    id: 1,
    title: "Accordion Project",
    component: (
      <Accordion title="Accordion Project" content="Accordion Project" />
    ),
  },
  {
    id: 2,
    title: "Random Color Generator HEX, RGB",
    component: (
      <RandomColor
        title="Random Color Generator HEX, RGB"
        content="Description of project 2."
      />
    ),
  },
  {
    id: 3,
    title: "Give me a star (★)",
    component: (
      <StartRating
        noOfStars={12}
        title="Give me a star (★)"
        content="Give me a star (★)"
      />
    ),
  },
  {
    id: 4,
    title: "Image Slider",
    component: (
      <ImageSlider
        url={`https://picsum.photos/v2/list`}
        limit={"15"}
        page={"2"}
        title="Image Slider"
        content="Image Slider"
      />
    ),
  },
  // {
  //   id: 5,
  //   title: "Load More Data",
  //   component: (
  //     <div className="project-box overflow-hidden scroll">
  //       <LoadMoreData title="Project 5" content="Description of project 5." />
  //     </div>
  //   ),
  // },

  {
    id: 6,
    title: "Tree View",
    component: (
      <TreeView TreeView menus={Menu} title="Tree View" content="Tree View" />
    ),
  },

  {
    id: 7,
    title: "Qr Code Generator",
    component: (
      <QRCodeGenerator title="Qr Code Generator" content="QR Code Generator" />
    ),
  },
  {
    id: 8,
    title: "Dark Light mode",
    component: (
      <LightDarkMode title="Dark Light mode" content="Dark Light mode" />
    ),
  },
  {
    id: 9,
    title: "Tab Component",
    component: (
      <TabTest title="Tab Component" content="Description of Tab Component" />
    ),
  },
  {
    id: 10,
    title: "Custom Modal",
    component: (
      <ModalTest title="Custom Modal" content="Description of Custom Modal." />
    ),
  },
  {
    id: 11,
    title: "GitHub Profile Finder",
    component: (
      <GithubProfileFinder
        title="GitHub Profile Finder"
        content="Description of GitHub Profile Finder."
      />
    ),
  },
  {
    id: 12,
    title: "Search Autocomplete",
    component: (
      <SearchAutocomplete
        title="Search Autocomplete"
        content="Description of Search Autocomplete."
      />
    ),
  },
  {
    id: 13,
    title: "Tic-Tac-Toe",
    component: (
      <TicTacToe title="Tic-Tac-Toe" content="Description of Tic-Tac-Toe" />
    ),
  },
];

export default projectsData;
