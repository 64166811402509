import React from "react";
import useAOS from "../../assets/Util/aos";

const Experience = () => {
  useAOS("FlipLeft");
  const experiences = [
    {
      title: "Software Developer",
      company: "HERMES ENGINEERING OOD · Full-time",
      duration: "Feb 2024 - Present",
      description: `
      As a Full Stack Developer at Hermes, I am responsible for supporting and developing the company's platforms for buying and selling parts, ensuring high stability and functionality. My key responsibilities include implementing new features and modules tailored to business needs, optimizing the user experience, and debugging the platform. I also create innovative solutions to adapt the platform to dynamic business requirements.
      Technologies used: TypeScript, Angular, NestJS, TypeORM, PgAdmin.
    `,
    },
  ];

  return (
    <div id="experience" className="bg-slate-600 text-white py-12">
      <div className="max-w-6xl mx-auto px-6 lg:px-8">
        <h2 className="text-3xl lg:text-4xl font-bold mb-10 text-center text-gray-300">
          Experience
        </h2>
        <div className="space-y-8" data-aos="flip-left">
          {experiences.map((exp, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            >
              <h3 className="text-2xl font-semibold text-yellow-400">
                {exp.title}
              </h3>
              <p className="text-lg text-gray-300">
                <span className="font-bold">{exp.company}</span> &middot;{" "}
                <span>{exp.duration}</span>
              </p>
              <p className="mt-4 text-gray-400 font-medium text-lg">
                {exp.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
