import { useState } from "react";

export const Tabs = ({ tabsContent, onChange }) => {
  const [currentTabsIndex, setCurrentTabsIndex] = useState(0);

  const handleOnClick = (getCurrentIndex) => {
    setCurrentTabsIndex(getCurrentIndex);
    onChange(getCurrentIndex);
  };

  return (
    <div className="w-full max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
      <div className="flex border-b border-gray-300 mb-4">
        {tabsContent.map((tabItem, index) => (
          <div
            onClick={() => handleOnClick(index)}
            key={tabItem.label}
            className={`cursor-pointer py-2 px-4 text-center text-lg font-medium ${
              currentTabsIndex === index
                ? "text-blue-600 border-b-2 border-blue-600"
                : "text-gray-500 hover:text-blue-600"
            }`}
          >
            <span>{tabItem.label}</span>
          </div>
        ))}
      </div>
      <div className="content p-4 bg-gray-100 rounded-md shadow-sm">
        {tabsContent[currentTabsIndex] && tabsContent[currentTabsIndex].content}
      </div>
    </div>
  );
};
