import { useState } from "react";
import QRCode from "react-qr-code";

export const QRCodeGenerator = () => {
  const [qrCode, setQrCode] = useState("");
  const [input, setInput] = useState("");

  const handleGenerateQRCode = () => {
    setQrCode(input);
    setInput("");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          QR Code Generator
        </h1>
        <div className="mb-4">
          <input
            onChange={(e) => setInput(e.target.value)}
            type="text"
            name="qr-code"
            placeholder="Enter your value here"
            value={input}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-6 text-center">
          <button
            disabled={input && input.trim() !== "" ? false : true}
            onClick={handleGenerateQRCode}
            className={`w-full py-3 px-4 bg-blue-500 text-black font-semibold rounded-lg transition-all duration-300 ${
              input.trim() === ""
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-600"
            }`}
          >
            Generate QR Code
          </button>
        </div>
        {qrCode && (
          <div className="flex justify-center">
            <QRCode
              id="qr-code-value"
              value={qrCode}
              size={256}
              bgColor="#fff"
            />
          </div>
        )}
      </div>
    </div>
  );
};
