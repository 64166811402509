import { MenuItem } from "./menu-item";

export const MenuList = ({ list = [] }) => {
  return (
    <ul className="pl-4 mt-2">
      {list && list.length
        ? list.map((listItem) => (
            <MenuItem key={listItem.label} item={listItem} />
          ))
        : null}
    </ul>
  );
};
